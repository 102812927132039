@import url(../../constants/colours.css);

.header {
  background-color: var(--dark-background);
  box-shadow: 0px 1px 5px var(--shadow);
  padding-bottom: 1rem;
}

.header > div {
  width: 80%;
  margin: 0 auto;
}

header > div > a:hover {
  color: var(--hover-text-color);
}

.contact-link {
  display: flex;
  align-items: center;
}

.contact-link > i {
  margin-right: 0.3rem;
}

@media only screen and (max-width: 800px) {
  .header > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }
}

@media only screen and (max-width: 400px) {
  .header > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

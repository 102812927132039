.content > h1 {
  font-size: 3rem;
  font-weight: 400;
  text-align: center;
}

.content > h2 {
  font-size: 2rem;
  font-weight: 200;
  text-align: center;
}

.card-holder {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 200px));
  grid-gap: 50px;
  justify-content: space-around;
}


/* icon css */

.icons{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
  grid-gap: 0px;
  justify-content: space-around;
  
}


.block1{
  position: relative;
  width: 100%;
  height: 18vh;
  margin: 50px;
  left: -30px;
  transition: 0.5s;
}

.block1:hover{
  box-shadow: 7px 7px 20px grey;
}

@media screen and (max-width:630px) {
  .block1{
    left: -50px;
  }  
}

.block1 img{
  position: absolute;
  width:27%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
}
.block1 a{
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  font-size: 110%;
}
.block1 p{
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  font-size: 110%;
}

.block1 a:hover{
  color:var(--hover-text-color);
}
.block1 p:hover{
  color:var(--hover-text-color);
}

.commu{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
  grid-gap: 0px;
  justify-content: space-around;
}

.whats{
  position: relative;
  width: 100%;
  height: 35vh;
  left: 80px;
  transition: 0.5s;
}
.whats:hover{
  box-shadow: 7px 7px 20px grey;
}
.whats img{
  position: absolute;
  width: 30%;
  top: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  
}
.whats p{
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 50px;
}
.map{
  position: relative;
  height: 45vh;
  left: -100px;
}
.map iframe{
  position: absolute;
  width: 100%;
  height: 35vh;
  top: 0;
}

.map p{
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  font-size: 110%;

}

@media screen and (max-width:1360px) {
  .map{
    width: 100%;
    height: 45vh;
    left: 7%;
    
  }

  .map iframe{
    position: absolute;
    width: 100%;
    height: 35vh;
    top: 0;
  }

  .whats{
    position: relative;
    width: 100%;
    height: 40vh;
    left: 7%;
  }
}

@media screen and (max-width:630px) {
  .commu{
    margin-left: -40px;
    margin-top: -20px;
  }
}

@media screen and (max-width:600px) {
  .block1 p{
    top: 50px;
  }
  .whats{
    height: 30vh;
    margin-bottom:20px ;
    
  }
  .whats p{
   bottom: 10px; 
  }
}

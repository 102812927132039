.card {
  width: 20rem;
  height: 26rem;
  border: 1px solid grey;
  border-radius: 0.5rem;
  margin: 1rem auto;
  transition: ease-in-out 0.3s;

}

.card:hover {
  box-shadow: 7px 7px 20px grey;
  transition: ease-in-out 0.3s;
}

.image-container {
  width: 90%;
  height: 50%;
  margin: 0.3rem auto;
  border-radius: 0.5rem;
  
}
.image-container img{
  width: 100%;
  margin-top: 10px;
 
}

.description {
  width: 90%;
  height: auto;
  margin: 0.9rem auto;
  border-radius: 0.5rem;
  padding: 0.5rem; 

}
.description p{
  white-space: pre-line;
  transition: 0.5s ease-in-out;
  font-size: 20px;
  
}

.name{
  width: 100%;
  text-align: center;
}
